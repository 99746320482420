<template>

  <v-layout row wrap align-content-center>
    <v-flex lg12 v-if="estadoRegistro=='PENDIENTE FIRMA' && consultado">
       <v-alert color="orange" outlined :value="true" text class="body-2">
          Un operador, realizo cambios a tu solicitud de registro por lo tanto debes volver a firmar tu formulario, para continuar con tu tramite
          <v-btn :loading="loading" color="success" rounded small @click="firmarRegistro()">presiona aqui</v-btn>
        </v-alert>
    </v-flex>
    <v-flex lg12 v-if="consultado && metodoPago.solicitudPasarelaPago && metodoPago">
     <v-row v-if="metodoPago.solicitudPasarelaPago">
        <v-col cols="12" v-if="['CREADO','SOLICITADO','EN_PROCESO'].includes(metodoPago.solicitudPasarelaPago.estado)" class="py-3">
          <v-alert color="primary" outlined :value="true" text class="body-2">
            Usted tiene pendiente una solicitud de pago mediante la <b>PASARELA DE PAGOS</b>, para continuar debe 
            <span v-if="metodoPago.solicitudPasarelaPago.metodoPago"> realizar el pago mediante <b>{{metodoPago.solicitudPasarelaPago.metodoPago}}</b> que se muestra a continuacion:</span>
            <span v-else><v-btn color="primary" rounded small @click="getUrlRedireccion(metodoPago.solicitudPasarelaPago.id)">presionar aquí</v-btn> para realizar el pago</span><span v-if="metodoPago.solicitudPasarelaPago.codigoTransaccion"> (código transacción: {{metodoPago.solicitudPasarelaPago.codigoTransaccion}})</span>
          </v-alert>
        </v-col>
      </v-row>
      <codigo-pago 
        v-if="metodoPago.cpt!== null" 
        :cpt="metodoPago.cpt" 
        :center="true"
      />
      <qr-pago 
        v-if="metodoPago.qr!== null" 
        :qr="metodoPago.qr" 
        :center="true"
        :vertical="qrVertical"
      />
    </v-flex>
      <v-flex :class="estilo">
          <span class="font-weight-bold">Tipo Registro : </span> <v-chip :color="colorEstado(tipoRegistro)">{{tipoRegistro}}</v-chip>
      </v-flex>
       <v-flex :class="estilo">
            <span class="font-weight-bold"> Estado del Registro : </span> <v-chip :color="colorEstado(estadoAbogado)">{{estadoAbogado}}</v-chip>
      </v-flex>
       <v-flex :class="estilo">
            <span class="font-weight-bold"> Estado del Trámite : </span> <v-chip :color="colorEstado(estadoRegistro)">{{estadoRegistro}}</v-chip>
      </v-flex>

  </v-layout>

</template>
<script>

export default {
  props:{
    estilo:{
      type:String,
      default:'lg4 sm4 xs12 text-center'
    },
    qrVertical:{  
      type:Boolean,
      default:false
    }
  },
  components:{
    'codigo-pago':()=>import("@/components/Cpt"),
    'qr-pago':()=>import("@/components/QrPago")
  },
  data(){
    return{
      loading:false,
      // metodoPago:{
      //   solicitudPasarelaPago:null,
      //   qr:null,
      //   cpt:null
      // },
      consultado:false
    }
  },
  created(){
    if(this.$store.state.abogado.metodoPago.solicitudPasarelaPago){
      if(['CREADO','SOLICITADO','EN_PROCESO'].includes(this.$store.state.abogado.metodoPago.solicitudPasarelaPago.estado)){
        if( ['SOLICITADO','EN_PROCESO'].includes(this.$store.state.abogado.metodoPago.solicitudPasarelaPago.estado) &&
            this.$store.state.abogado.metodoPago.qr===null &&
            this.$store.state.abogado.metodoPago.cpt===null
          ){
            this.getInformacionMetodoPago()
          }
        this.metodoPago = this.$store.state.abogado.metodoPago
      }
    }
    this.consultado = true
  },  
  computed: {
    estadoRegistro() {
      return this.$store.state.abogado.estadoRegistro;
    },
    estadoAbogado() {
      return this.$store.state.abogado.estadoAbogado;
    },
    tipoRegistro() {
      return this.$store.state.abogado.tipoRegistro;
    },
    metodoPago(){
      return this.$store.state.abogado.metodoPago
    }
  },
  
  methods:{
      colorEstado(estado){
           if(estado=='MIGRACION'||estado=='NORMAL'||estado=='CIUDADANIA'){
           return 'primary lighten-3';   
          }
          if(estado=='NUEVO'){
           return 'primary justify';   
          }
          if(estado=='PENDIENTE'){
           return 'warning';   
          }
          if(estado=='CONCLUIDO'){
           return 'success';   
          }
          return 'default'
      },
      firmarRegistro(){
        this.loading=true
        this.$http.put(`${this.$apiUrl}abogado-ciudadano/solicitar-firma-registro`).then(response=>{
              this.loading=false
              if(response.data.data){
                this.$store.commit('abogado/setEstadoRegistro', 'PRE-REGISTRADO');
                if(response.data.data.aprobacionCiudadania.linkAprobacion) {
              this.$notify({
                    group:'foo',
                    type:'success',
                    title:'APROBACION DOCUMENTOS',
                    text:'Debe aprobar los documentos de su solicitud con Ciudadanía Digital'
                })
               window.location.href = response.data.data.aprobacionCiudadania.linkAprobacion
            }
              }
          }).catch(error=>{
            console.log(error)
            this.loading=false
        })
      },
      getUrlRedireccion(id){
       this.$store.commit('setMessageOverlay', 'Obteniendo informacion de la pasarela de pagos')
       this.$store.commit('setOverlay', true);
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/solicitud-pasarela-pago/${id}/url-redireccion`).then(response=>{
        const {data, error} = response.data
        if(data){
          if(!error && data.estado === 'SOLICITADO' ){
            if(data.codigoTransaccion){
              this.$storage.set('codigo_transaccion',data.codigoTransaccion)
            }
            this.goUrl(data.urlRedireccion)
            return
          }
          if(!error && data.estado === 'EN_PROCESO' ){
            if(data.qr){
              this.metodoPago.qr = null
              const e = data.qr
              this.metodoPago.qr = {
                  id: e.id, 
                  idQr: e.id_qr,
                  codigoQr: e.codigo_qr||null,
                  monto: e.monto,
                  estado: e.estado_codigo.nombre,
                  tipoTramite: "CREDENCIAL",
                  fechaCreacion: e.fecha_creacion,
                  activo: true
              }
              this.metodoPago.solicitudPasarelaPago.metodoPago = 'QR'
            }else{
              this.metodoPago.cpt = null
               const e = data.cpt
              this.metodoPago.cpt = {
                  id: e.id, 
                  codigo: e.codigo_cpt,
                  fechaInicio: e.fecha_inicio,
                  fechaVencimiento: e.fecha_vencimiento,
                  estado: e.estado_codigo.nombre,
                  monto: e.monto
              }
              this.metodoPago.solicitudPasarelaPago.metodoPago = 'PPTE'
            }
            // this.goUrl(data.urlRedireccion)
          }
        }
        this.$store.commit('setOverlay', false);
       }).catch(error=>{
        this.$store.commit('setOverlay', false);
       })
    },
    goUrl(url){
      window.location.href = url
    },
    getInformacionMetodoPago(){
      this.$http.get(`${this.$apiUrl}abogado-ciudadano/metodo-pago-registro`)
      .then(response=>{
          const {data} = response.data
          if(data !== null){
            this.metodoPago = data
            // this.$store.commit('abogado/setMetodoPago',data)
          }
      }).catch(error=>{
          console.log('------------------------------------');
          console.log(error);
          console.log('------------------------------------');
      })
    },
  }
};
</script>

