<template>
  <v-card>
    <v-toolbar color="primary" dark>INFORMACIÓN GENERAL</v-toolbar>
    <v-card-text class="px-5">
      <v-layout row wrap>
        <div class="flex lg5 md4 xs4 font-weight-bold grey--text">Matrícula:</div>
        <div class="flex lg7 md7 xs7  black--text">{{matricula}}</div>
        <div class="flex lg5 md4 xs4 font-weight-bold grey--text">Nombre:</div>
        <div class="flex lg7 md7 xs7  black--text">{{nombreCompleto}}</div>
        <div class="flex lg5 md4 xs4 font-weight-bold grey--text">Fecha Nacimiento:</div>
        <div class="flex lg7 md7 xs7 black--text">{{fechaNacimiento}}</div>
        <div class="flex lg5 md4 xs4 font-weight-bold grey--text">Género:</div>
        <div class="flex lg7 md7 xs7  black--text">{{genero}}</div>
        <div class="flex lg5 md4 xs4 font-weight-bold grey--text">Celular:</div>
        <div class="flex lg7 md7 xs7  black--text">{{celular}}</div>
        <div class="flex lg5 md4 xs4 font-weight-bold grey--text">Correo:</div>
        <div class="flex lg7 md7 xs7  black--text">{{correo}}</div>
      </v-layout>
      <v-layout row wrap class="pb-5">
        <EstadoRegistro :estilo="'lg12 xs12'" :qrVertical="true" />
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
import EstadoRegistro from "@/components/EstadoRegistro";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    EstadoRegistro
  },
  computed: {
    ...mapState({ matricula: state => state.abogado.registro.matricula,
    fechaNacimiento:state=> state.abogado.persona.fechaNacimiento,
    genero:state=> state.abogado.persona.genero,
    celular:state=> state.abogado.persona.celular,
    correo:state=> state.abogado.persona.correo,
     }),
    nombreCompleto() {
      return this.$store.getters['abogado/getNombreCompleto'];
    }
  }
};
</script>
